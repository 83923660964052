import { SignIn } from "@clerk/nextjs";
import type { GetStaticPaths, GetStaticPropsContext } from "next";

export default function Page() {
  return (
    <div className="mx-auto max-w-max pt-24">
      <SignIn />
    </div>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const messages = (
    (await import(`@/../messages/${locale}.json`)) as {
      default: IntlMessages;
    }
  ).default;

  return {
    props: {
      messages,
    },
  };
}

export const getStaticPaths: GetStaticPaths<{ slug: string }> = () => {
  return {
    paths: [], //indicates that no page needs be created at build time
    fallback: "blocking", //indicates the type of fallback
  };
};
